"use client";

import React from "react";
import { Modal } from "./Modal";
import { AssetDependencyEditor } from "../editors/asset-dependency-editor/editor";
import { Asset, Project, transact, transactDryRun, Workspace } from "@palette.tools/model.client";
import { TypographyH3 } from "../typography";
import { DependencyIcon } from "@palette.tools/react.icons";


export const AssetDependencyModal: React.FC<{
  open: boolean,
  onClose?: () => void,
  workspace: Workspace | null,
  project: Project | null,
  asset: Asset,
}> = ({
  open,
  onClose,
  workspace,
  project,
  asset,
}) => {

  const [[ expandedProject ]] = Project.useCustom({
    $: {
      where: {
        id: project?.id || "never",
      },
    },
    category: {
      asset: {},
    }
  });

  const [[ expandedAsset ]] = Asset.useCustom({
    $: {
      where: {
        id: asset?.id || "never",
      },
    },
    inputs: {},
    outputs: {},
  });

  const inputs = expandedAsset?.links.inputs || [];
  const outputs = expandedAsset?.links.outputs || [];

  return <Modal
    open={open}
    className="max-h-[80vh] min-h-[80vh] min-w-[800px] max-w-[800px] flex flex-col"
    formClassName="min-h-0 flex-1 flex flex-col"
    containerClassName="w-full flex flex-col content-center items-center gap-x-2 min-h-0 flex-1"
    secondaryButtonContent="Close"
    headerIcon={<DependencyIcon width={16} height={16} />}
    header="Dependencies"
    onCancel={() => onClose?.()}
    onClose={() => onClose?.()}
  >
    <AssetDependencyEditor
      model={{
        workspace: workspace || null,
        project: expandedProject || null,
        asset: expandedAsset || null,
        inputs: expandedAsset?.links.inputs || [],
        outputs: expandedAsset?.links.outputs || [],
        categories: expandedProject?.links.category || [],
        assetsByCategory: expandedProject?.links.category?.reduce((acc, category) => {
          acc[category.id] = category.links.asset || [];
          return acc;
        }, {} as Record<string, Asset[]>) || {},
      }}
      callbacks={{
        onAddInputs: async (_asset: Asset, _inputs: string[]) => {
          await transact(
            _inputs.map(x => _asset.link('asset/inputs', x)),
          );
        },
        onRemoveInputs: async (_asset: Asset, _inputs: string[]) => {
          await transact(
            _inputs.map(x => _asset.unlink('asset/inputs', x)),
          );
        },
        onAddOutputs: async (_asset: Asset, _outputs: string[]) => {
          await transact(
            _outputs.map(x => _asset.link('asset/outputs', x)),
          );
        },
        onRemoveOutputs: async (_asset: Asset, _outputs: string[]) => {
          await transact(
            _outputs.map(x => _asset.unlink('asset/outputs', x)),
          );
        },
      }}
      className="flex-1 w-full h-full min-h-0"
    />
  </Modal>

}

