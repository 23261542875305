"use client";

import React, { useCallback, useRef, useLayoutEffect } from 'react';

interface ScrollPosition {
  top: number;
  left: number;
}

export const useStickyScroll = ({key, permanent = false}: {key: string, permanent?: boolean}) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const restoredRef = useRef(false);
  const observerRef = useRef<ResizeObserver | null>(null);
  const isProgrammaticScrollRef = useRef(false);
  const preventSavingRef = useRef(true);  // Start with preventing saves

  const saveScrollPosition = useCallback(() => {
    if (scrollRef.current && !isProgrammaticScrollRef.current && !preventSavingRef.current) {
      const position = {
        top: scrollRef.current.scrollTop,
        left: scrollRef.current.scrollLeft,
      };
      console.log('Saving scroll position', position);
      const storage = permanent ? localStorage : sessionStorage;
      storage.setItem(`scrollPosition_${key}`, JSON.stringify(position));
    }
  }, [key, permanent]);

  const setRef = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      scrollRef.current = node;
      
      const storage = permanent ? localStorage : sessionStorage;
      const savedPosition = storage.getItem(`scrollPosition_${key}`);

      console.log('Saved position:', savedPosition);

      if (savedPosition && !restoredRef.current) {
        const { top, left } = JSON.parse(savedPosition) as ScrollPosition;
        
        console.log('Attempting to restore scroll position', { top, left, currentHeight: node.scrollHeight });
        
        observerRef.current = new ResizeObserver((entries) => {
          const [entry] = entries;
          const currentHeight = entry.contentRect.height;
          const clientHeight = node.clientHeight;
          console.log('Current height:', currentHeight, 'Client height:', clientHeight, 'Target scroll top:', top);
          if (currentHeight >= top + clientHeight) {
            console.log('Restoring scroll position', { top, left });
            isProgrammaticScrollRef.current = true;
            node.scrollTop = top;
            node.scrollLeft = left;
            isProgrammaticScrollRef.current = false;
            restoredRef.current = true;
            preventSavingRef.current = false;  // Allow saving after restoration
            observerRef.current?.disconnect();
          }
        });

        observerRef.current.observe(node);

        setTimeout(() => {
          if (!restoredRef.current) {
            console.log('Scroll restoration timed out');
            preventSavingRef.current = false;  // Allow saving after timeout
            observerRef.current?.disconnect();
          }
        }, 10000);
      } else {
        // If there's no saved position or we've already restored, allow saving immediately
        preventSavingRef.current = false;
      }
    }
  }, [key, permanent]);

  useLayoutEffect(() => {
    const scrollElement = scrollRef.current;
    if (!scrollElement) return;

    let scrollTimeout: NodeJS.Timeout | null = null;

    const handleScroll = () => {
      if (isProgrammaticScrollRef.current || preventSavingRef.current) {
        console.log('Programmatic scroll or saving prevented, not saving position');
        return;
      }

      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
      scrollTimeout = setTimeout(saveScrollPosition, 100);
    };

    scrollElement.addEventListener('scroll', handleScroll);

    // Save position when page is about to unload
    window.addEventListener('beforeunload', saveScrollPosition);

    return () => {
      scrollElement.removeEventListener('scroll', handleScroll);
      window.removeEventListener('beforeunload', saveScrollPosition);
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
      observerRef.current?.disconnect();
      
      // Save position when component unmounts, if saving is allowed
      if (!preventSavingRef.current) {
        saveScrollPosition();
      }
    };
  }, [key, permanent, saveScrollPosition]);

  return setRef;
};


export const useStickyCollapse = ({key, permanent = false}: {key: string, permanent?: boolean}) => {
  const [collapsedStates, setCollapsedStates] = React.useState<Record<string, boolean>>(() => {
    const savedStates = permanent ? localStorage.getItem(`collapsedStates_${key}`) : sessionStorage.getItem(`collapsedStates_${key}`);
    if (savedStates) {
      try {
        const parsed = JSON.parse(savedStates);
        // Ensure the parsed value is an object
        return typeof parsed === 'object' && parsed !== null ? parsed : {};
      } catch (error) {
        console.error('Error parsing saved collapsed states:', error);
        return {};
      }
    }
    return {};
  });

  React.useEffect(() => {
    try {
      // Only save if there are actual states to save
      if (Object.keys(collapsedStates).length > 0) {
        const serialized = JSON.stringify(collapsedStates);
        if (permanent) {
          localStorage.setItem(`collapsedStates_${key}`, serialized);
        } else {
          sessionStorage.setItem(`collapsedStates_${key}`, serialized);
        }
      }
    } catch (error) {
      console.error('Error saving collapsed states:', error);
      const storage = permanent ? localStorage : sessionStorage;
      storage.setItem(`collapsedStates_${key}`, '{}');
    }
  }, [collapsedStates, key, permanent]);

  const setCollapsed = React.useCallback((id: string, isCollapsed: boolean) => {
    setCollapsedStates(prev => ({...prev, [id]: isCollapsed}));
  }, []);

  const isCollapsed = React.useCallback((id: string, defaultValue = false) => {
    return id in collapsedStates ? collapsedStates[id] : defaultValue;
  }, [collapsedStates]);

  return [isCollapsed, setCollapsed, {
    collapsedStates: {...collapsedStates},
    setCollapsedStates,
  }] as const;
}

function useLocationChange(callback: () => void) {
  React.useEffect(() => {
    // Initial call with current location
    callback();

    // Handle browser back/forward navigation
    const handleLocationChange = () => callback();
    window.addEventListener('popstate', handleLocationChange);

    // Intercept history methods
    const originalPushState = history.pushState;
    const originalReplaceState = history.replaceState;

    history.pushState = function(...args) {
      originalPushState.apply(this, args);
      handleLocationChange();
    };

    history.replaceState = function(...args) {
      originalReplaceState.apply(this, args);
      handleLocationChange();
    };

    return () => {
      window.removeEventListener('popstate', handleLocationChange);
      history.pushState = originalPushState;
      history.replaceState = originalReplaceState;
    };
  }, [callback]);
}

const navigationStorage = new Map<string, any>();
let lastPathname: string | null = null;

export function useStickyValue<T>(
  key: string,
  initialValue: T,
  persistence: 'local' | 'session' | 'navigation' = 'session'
): [T, (value: T) => void] {
  const [value, setValue] = React.useState<T>(() => {
    // Initialize value same as before...
    if (persistence === 'navigation') {
      if (navigationStorage.has(key)) {
        return navigationStorage.get(key);
      }
      navigationStorage.set(key, initialValue);
      return initialValue;
    }

    const storage = persistence === 'local' ? localStorage : sessionStorage;
    const saved = storage.getItem(key);
    
    if (saved !== null) {
      try {
        return JSON.parse(saved);
      } catch (error) {
        console.error(`Error parsing stored value for ${key}:`, error);
        return initialValue;
      }
    }
    
    return initialValue;
  });

  // Use our new hook for location changes
  useLocationChange(React.useCallback(() => {
    if (persistence === 'navigation') {
      // Get pathname without query params or hash
      const currentPathname = window.location.pathname;
      if (currentPathname !== lastPathname) {
        navigationStorage.clear();
        lastPathname = currentPathname;
      }
    }
  }, [persistence]));

  // Save value effect remains the same
  React.useEffect(() => {
    if (persistence === 'navigation') {
      navigationStorage.set(key, value);
      return;
    }

    try {
      const storage = persistence === 'local' ? localStorage : sessionStorage;
      storage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(`Error saving value for ${key}:`, error);
    }
  }, [key, value, persistence]);

  return [value, setValue];
}