"use client";

import React from "react";
import { TaskState } from "firebase/storage";
import { Project, Workspace, uploadFile, validateWorkspaceFile } from "@palette.tools/model.client";
import { ProgressModal } from "../modals/ProgressModal";
import { InfoModal } from "../modals/InfoModal";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../shadcn/components/ui/tooltip";
import { flushSync } from "react-dom";

export interface FileUploadValidation {
  isValid: boolean;
  reason?: string;
}

export const useFileUpload = (
  workspace: Workspace | null,
  project: Project | null,
  onValidateFile: (f: File) => FileUploadValidation = (f) => validateWorkspaceFile(workspace, f),
) => {
  const canUpload = !!workspace && !!project;
  const [progress, setProgress] = React.useState<number>(0);
  const [validationError, setValidationError] = React.useState<string>("");
  const [uploadError, setUploadError] = React.useState<string>("");
  const [isUploading, setIsUploading] = React.useState<boolean>(false);

  const upload = async (
    file: File,
    callbacks?: {
      onCreateEntry?: (fileId: string, context: { nonce: string }) => void;
      onStart?: (context: { nonce: string }) => void;
      onProgress?: (state: TaskState, bytesTransferred: number, totalBytes: number, context: { nonce: string }) => void;
      onFinish?: (fileId: string | null, context: { nonce: string }) => void;
    }
  ) => {
    if (!canUpload) return;

    const nonce = crypto.randomUUID();
    let currentFileId: string | null = null;

    const validation = onValidateFile(file);
    if (!validation.isValid) {
      setValidationError(validation.reason || "File cannot be uploaded.");
      return;
    }

    flushSync(() => {
      setUploadError("");
      setIsUploading(true);
    });
    callbacks?.onStart?.({ nonce });
    setProgress(0.05);
    await uploadFile(
      workspace,
      project,
      file,
      {
        onCreateEntry: (_fileId) => {
          callbacks?.onCreateEntry?.(_fileId, { nonce });
          currentFileId = _fileId;
        },
        onProgress: (state, bytesTransferred, totalBytes) => {
          const adjusted_progress = ((bytesTransferred / totalBytes) * 0.90) + 0.05;
          setProgress(adjusted_progress);
          callbacks?.onProgress?.(state, bytesTransferred, totalBytes, { nonce });
        },
        nonce,
      }
    )
    .catch((e) => setUploadError(e.message))
    .finally(() => {
      setIsUploading(false);
      callbacks?.onFinish?.(currentFileId, { nonce });
    });
  };

  return {
    canUpload,
    progress,
    validationError,
    uploadError,
    isUploading,
    upload,
  };
};

const FileUploadContext = React.createContext<{
  isUploading: boolean;
  validationError: string;
  uploadError: string;
  progress: number;
  upload: (
    file: File,
    callbacks?: {
      onCreateEntry?: (fileId: string, context: { nonce: string }) => void;
      onStart?: (context: { nonce: string }) => void;
      onProgress?: (state: TaskState, bytesTransferred: number, totalBytes: number, context: { nonce: string }) => void;
      onFinish?: (fileId: string | null, context: { nonce: string }) => void;
    }
  ) => Promise<void>;
} | null>(null);

interface FileUploadProviderProps {
  workspace: Workspace | null;
  project: Project | null;
  children: React.ReactNode;
  blocking: boolean;
  onValidateFile?: (f: File) => FileUploadValidation;
}

export const FileUploadProvider: React.FC<FileUploadProviderProps> = ({
  workspace,
  project,
  children,
  blocking = true,
  onValidateFile = (f) => validateWorkspaceFile(workspace, f),
}) => {
  const { canUpload, progress, validationError, uploadError, isUploading, upload } = 
    useFileUpload(workspace, project, onValidateFile);

  return (
    <FileUploadContext.Provider 
      value={{
        isUploading,
        validationError,
        uploadError,
        progress,
        upload: canUpload ? upload : async () => {},
      }}
    >
      <InfoModal open={!!validationError}>{validationError}</InfoModal>
      {children}
      {isUploading && blocking ? <ProgressModal open={isUploading} progress={progress} error={uploadError}>Uploading...</ProgressModal> : null}
    </FileUploadContext.Provider>
  );
};

const useFileUploadContext = () => {
  const context = React.useContext(FileUploadContext);
  return context;
};

interface FileUploadTriggerProps<T extends React.ElementType> {
  children: React.ReactElement<React.ComponentPropsWithoutRef<T>>;
  disabled?: boolean;
  onCreateEntry?: (fileId: string, context: { nonce: string }) => void;
  onStart?: (context: { nonce: string }) => void;
  onProgress?: (state: TaskState, bytesTransferred: number, totalBytes: number, context: { nonce: string }) => void;
  onFinish?: (fileId: string | null, context: { nonce: string }) => void;
  allowableMimeTypes?: string[];
}

export const FileUploadTrigger = <T extends React.ElementType>({
  children,
  disabled: propDisabled,
  onCreateEntry,
  onStart,
  onProgress,
  onFinish,
  allowableMimeTypes,
}: FileUploadTriggerProps<T>) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const context = useFileUploadContext();
  const disabled = propDisabled || !context;

  const props: typeof children.props = {
    ...children.props,
    onClick() {
      if (disabled || !inputRef.current) return;
      inputRef.current.value = "";
      inputRef.current.click();
    }
  };

  const handleFileChange = async () => {
    if (!inputRef.current || !inputRef.current.files || inputRef.current.files.length < 1 || !context) {
      return;
    }
    const f = inputRef.current.files[0];
    context.upload(f, { onCreateEntry, onStart, onProgress, onFinish });
  };

  const clone = React.cloneElement(children, props);

  const trigger = disabled
    ? <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger asChild>{clone}</TooltipTrigger>
          <TooltipContent className="italic text-muted-foreground">Workspace not set up for uploading.</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    : clone;

  return (
    <>
      <input ref={inputRef} type="file" style={{ display: "none" }} onChange={handleFileChange} accept={allowableMimeTypes?.join(",")} />
      {trigger}
    </>
  );
};
