import React, { forwardRef } from 'react'
import { cn } from '@/lib/utils'
import { AssetDependencyEditorCallbacks, AssetDependencyEditorItem, AssetDependencyEditorTree, useAssetDependencyEditor } from './model'
import { AssetDependencyEditorModel } from './model'
import { Item, SelectionManager, useDogs } from '@palette.tools/react.dogs'
import { Emoji } from 'emoji-picker-react'
import { ChevronDownIcon } from 'lucide-react'
import { TextOverflowTooltip, useStickyCollapse } from '../../utils'
import { Asset } from '@palette.tools/model.client'
import { TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Tabs } from '@radix-ui/react-tabs'
import { TypographyH3, TypographyMedium } from '../../typography'
import ImageFallback from '../../image/ImageFallback'
import { TooltipProvider } from '@/components/ui/tooltip'


interface AssetDependencyTreeProps extends React.HTMLAttributes<HTMLDivElement> {
  asset: Asset,
  tree: AssetDependencyEditorTree,
  fromTree: AssetDependencyEditorTree,
  onInsertAssetIds: (assetIds: string[]) => void,
}


const AssetDependencyTree = forwardRef<HTMLDivElement, AssetDependencyTreeProps>(({
  className,
  asset,
  tree: { items, rowsById, ancestorsById, lastModified },
  fromTree: { items: fromItems, rowsById: fromRowsById, ancestorsById: fromAncestorsById, lastModified: fromLastModified },
  onInsertAssetIds,
  ...props
}, ref) => {

  const { render } = useDogs({
    items,
    selectionMode: "multi",
    enableOrdering: true,
    treatChildrenAsParentBody: true,
    externalDataKey: "asset-dependency-editor",
    allowInsertExternal: "asset-dependency-editor",
    insertExternalItems: (insertedItems, index, parentId, externalDataKey, setSelectedIds) => {
      if (externalDataKey !== "asset-dependency-editor") return;
      const insertedRows = insertedItems.map(x => fromRowsById[x.__id]).filter(Boolean);
      const assetIds = insertedRows.map(x => x.rowType === 'asset' ? x.id : undefined).filter(Boolean) as string[];
      assetIds.length > 0 && onInsertAssetIds(assetIds);
      setSelectedIds(new Set(insertedItems.map(x => x.__id)));
    }
  })

  // Collapse
  const [isCollapsed, setCollapsed] = useStickyCollapse({ key: "asset-dependency-editor-collapsed" + asset.id });
  const toggleCollapsed = React.useCallback((id: string, defaultValue?: boolean) => setCollapsed(id, !isCollapsed(id, defaultValue)), [
    isCollapsed,
    setCollapsed,
  ]);

  return render({

    root({ children, ...state }) {
      const isEmpty = items.length === 0;
      return <div
        key={state.id}
        ref={state.ref}
        className={cn(
          // Layout
          "flex flex-col p-[8px]",

          // Border styles
          isEmpty ?
            state.isDroppingWithinDescendant ?
              "rounded-lg dashed-base dashed-md dashed-primary"
            : "rounded-lg dashed-base dashed-md dashed-muted-foreground"
          : state.isDroppingWithinDescendant ?
            "rounded-lg border-primary border-[1px]"
            : "rounded-lg border-border border-[1px]",

          className,
        )}
      >
        {items.length > 0 ? undefined : <div className="flex flex-col flex-1 items-center justify-center h-full text-sm text-muted-foreground select-none">Drag assets here</div>}
        {children}
      </div>
    },
    rootRenderDeps({ ...state }) {
      return [
        state.id,
        state.isDroppingWithinDescendant,
        items.length,
      ];
    },

    group({ children, group, ...state }) {
      return <React.Fragment key={state.id}>
        <div
          ref={state.ref}
          dogs-selection-enabled="false"
          onClick={() => toggleCollapsed(state.id)}
          className={cn(

            // Base
            "flex flex-row items-center gap-x-[4px] rounded-sm p-[3px]",

            // Text
            "text-sm text-muted-foreground select-none",

            // Background
            state.isHovering && !state.isDragging ? 'bg-muted' : undefined,

          )}
        >
          <ChevronDownIcon
            dogs-selection-enabled="false"
            width={16}
            height={16}
            className={cn("stroke-muted-foreground transition-transform duration-100", isCollapsed(state.id) ? "-rotate-90" : "")}
          />
          {group.data.rowType === 'category' ? <Emoji unified={group.data.emoji} size={16} /> : undefined}
          {group.data.title}
        </div>
        {!isCollapsed(state.id) && children}
      </React.Fragment>
    },
    groupRenderDeps({ ...state }) {
      return [
        state.id,
        state.isSelected,
        state.isHovering,
        state.isDraggingThis,
        state.group.data.title,
        state.group.__children.length,
        isCollapsed(state.id),
      ];
    },

    child({ item, ...state }) {
      return <div
        key={state.id}
        ref={state.ref}
        className={cn(

          // Base
          "flex flex-row items-center select-none pl-[16px]",

        )}
      >
        <div className={cn(
          "flex flex-row flex-1 items-center gap-x-[4px] py-[3px] px-[12px] rounded-sm text-sm",

          // Text
          state.isDraggingThis ? "text-muted-foreground" : undefined,

          // Background
          state.isHovering && !state.isDragging ? 'bg-muted' : state.isSelected ? 'bg-muted/50' : undefined,

        )}>
          {item.data.title}
        </div>
      </div>
    },
    childRenderDeps({ ...state }) {
      return [
        state.id,
        state.isSelected,
        state.isHovering,
        state.isDraggingThis,
        state.item.data.title,
      ];
    },


    dragImage({ ref, draggedItems, isExternalDrag, draggedIds, ...state }) {
      return <div ref={ref} className="w-fit h-fit pl-4 w-screen max-w-[300px]">
        {draggedItems.map(x => rowsById[x.__id]).filter(Boolean).map(x => x.title).join(", ")}
      </div>
    },

  })
})
AssetDependencyTree.displayName = "AssetDependencyTree"


interface AssetDependencyEditorProps extends React.HTMLAttributes<HTMLDivElement> {
  model: AssetDependencyEditorModel,
  callbacks: AssetDependencyEditorCallbacks,
}


export const AssetDependencyEditor = forwardRef<HTMLDivElement, AssetDependencyEditorProps>(({
  className,
  model,
  callbacks,
  ...props
}, ref) => {

  const modelRef = React.useRef(model);
  modelRef.current = model;
  const callbacksRef = React.useRef(callbacks);
  callbacksRef.current = callbacks;

  const {
    inputTree,
    inputAvailableTree,
    outputTree,
    outputAvailableTree,
    inputIds,
    outputIds,
  } = useAssetDependencyEditor(model);

  const [tab, setTab] = React.useState<'inputs' | 'outputs'>('inputs');

  if (!model.asset) return null;

  return <TooltipProvider>
    <div ref={ref}
      {...props}
      className={cn(
        "flex flex-row gap-x-[16px] pb-[16px]",
        className,
      )}
    >

      <div className="flex flex-col flex-1 gap-y-[10px]">

        <div className="flex flex-row items-center gap-x-[8px]">
          <ImageFallback
            className="rounded-md"
            src={model.asset.data.thumbnail_url}
            width="80"
            height="45"
            alt={`Profile picture for ${model.asset.data.name || "Unknown Asset"}`}
          />
          <div className="flex flex-1 h-full items-center justify-start">
            <TextOverflowTooltip
              tooltipText={model.asset.data.name || ""}
            >
              <TypographyMedium className="text-sm truncate break-all line-clamp-2 text-ellipsis">
                {model.asset.data.name}
              </TypographyMedium>
            </TextOverflowTooltip>
          </div>
        </div>

        <Tabs defaultValue="inputs" className="w-full" onValueChange={value => setTab(value as "inputs" | "outputs")}>
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="inputs" className={cn(
              "flex flex-row items-center gap-x-[4px]",
              // Ignore focus
              "outline-0 focus:outline-0 focus-visible:outline-0 ring-0 focus:ring-0 focus-visible:ring-0 ring-offset-0 focus:ring-offset-0 focus-visible:ring-offset-0",
            )} tabIndex={-1}>
              <span className="flex-1" />
              <span>Needs</span>
              <div className="flex flex-row flex-1 items-center justify-center gap-x-[4px]">
                {inputIds.size > 0 ? <span className="bg-secondary px-[4px] rounded-sm">{inputIds.size}</span> : undefined}
              </div>
            </TabsTrigger>
            <TabsTrigger value="outputs" className={cn(
              "flex flex-row items-center gap-x-[4px]",
              // Ignore focus
              "outline-0 focus:outline-0 focus-visible:outline-0 ring-0 focus:ring-0 focus-visible:ring-0 ring-offset-0 focus:ring-offset-0 focus-visible:ring-offset-0",
            )} tabIndex={-1}>
              <span className="flex-1" />
              <span>Needed by</span>
              <div className="flex flex-row flex-1 items-center justify-center gap-x-[4px]">
                {outputIds.size > 0 ? <span className="bg-secondary px-[4px] rounded-sm">{outputIds.size}</span> : undefined}
              </div>
            </TabsTrigger>
          </TabsList>
        </Tabs>

        {tab === 'inputs' ? (
          <AssetDependencyTree
            key={'inputTree'}
            asset={model.asset}
            className="flex-1 overflow-y-auto min-h-0"
            tree={inputTree}
            fromTree={inputAvailableTree}
            onInsertAssetIds={assetIds => callbacks.onAddInputs(model.asset, assetIds)}
          />
        ) : (
          <AssetDependencyTree
            key={'outputTree'}
            asset={model.asset}
            className="flex-1 overflow-y-auto min-h-0"
            tree={outputTree}
            fromTree={outputAvailableTree}
            onInsertAssetIds={assetIds => callbacks.onAddOutputs(model.asset, assetIds)}
          />
        )}

      </div>

      {tab === 'inputs' ? (
        <AssetDependencyTree
          key={'inputAvailableTree'}
          asset={model.asset}
          className="flex-1 overflow-y-auto min-h-0"
          tree={inputAvailableTree}
          fromTree={inputTree}
          onInsertAssetIds={assetIds => callbacks.onRemoveInputs(model.asset, assetIds)}
        />
      ) : (
        <AssetDependencyTree
          key={'outputAvailableTree'}
          asset={model.asset}
          className="flex-1 overflow-y-auto min-h-0"
          tree={outputAvailableTree}
          fromTree={outputTree}
          onInsertAssetIds={assetIds => callbacks.onRemoveOutputs(model.asset, assetIds)}
        />
      )}

    </div>
  </TooltipProvider>

})
AssetDependencyEditor.displayName = "AssetDependencyEditor"