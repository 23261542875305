import { instantDBCore } from "./core";

export function setQueryTimeout(query: Parameters<typeof instantDBCore._reactor.subscribeQuery>[0], cb: (result: any) => void, timeout?: number) {
  const unsubscribe = instantDBCore._reactor.subscribeQuery(query, (result: any) => {
    unsubscribe();
    cb(result);
  });

  if (timeout !== undefined) {
    setTimeout(() => {
      unsubscribe();
    }, timeout);
  }
}