"use client";

import React from "react";

import {
  ChildLinkTypes,
  Namespaces,
  SchemaEntry,
  WhereClause,
  query_custom,
  query_deep,
  query_grandchildren,
  query_many,
  query_split,
  query_where,
} from "@palette.tools/model.core";

import {
  Entity,
  EntityStatic as EntityStatic_core,
  InstantDB,
  QueryOptions,
  createEntityClass as createEntityClass_core,
  query_one,
  transform_data,
} from "@palette.tools/model.core";
import { InstantObject, id, instantDBCore, tx, useInstantDBQuery, useQuery } from ".";
import type { Exactly, Query } from "@instantdb/core";

const db: InstantDB = {
  tx: tx,
  id: id,
}

// Use

interface UseOptions<K extends keyof Namespaces> {
  preloadedData?: InstantObject;
  queryOptions?: QueryOptions<K>;
}

function useData<K extends keyof Namespaces>(
  key: K,
  id: string = "never",
  options: UseOptions<K> = {},
): [InstantObject | null, ReturnType<typeof useQuery>] {

  const _query = query_one(key, id, options?.queryOptions);
  if (key === "project") {
    //console.log("useData query", JSON.stringify(_query));
  }
  const queryResult = useQuery(_query as Exactly<Query, Query>);
  if (key === "project") {
    //console.log("useData queryResult", JSON.stringify(queryResult));
  }
  const entity = (queryResult?.data) && (Array.isArray(queryResult.data[key])) && queryResult.data[key].length === 1 && queryResult.data[key][0] !== null ? queryResult.data[key][0] : null;

  if (entity === null && !!options.preloadedData) {
    return [options.preloadedData, {...queryResult, isLoading: false}]
  }

  return [entity, queryResult];

}


function useTransformedData<K extends keyof Namespaces, T = any>(
  key: K,
  id: string = "never",
  transform: (data: InstantObject) => T,
  options: UseOptions<K> = {},
): [T | null, ReturnType<typeof useQuery>] {
  const [entity, queryResult] = useData(key, id, options);
  return [entity ? transform(entity) : null, queryResult];
}

interface UseWhereOptions<K extends keyof Namespaces> {
  preloadedData?: InstantObject[];
  queryOptions?: QueryOptions<K>;
}

// Use where

function useWhere<K extends keyof Namespaces>(
  key: K,
  where: WhereClause,
  options: UseWhereOptions<K> = {},
): [InstantObject[], ReturnType<typeof useQuery>] {
  const _query = query_where(key, where, options?.queryOptions);
  const queryResult = useQuery(_query as Exactly<Query, Query>);
  const response = queryResult.data;
  const entities = response && (response[key]) && (Array.isArray(response[key])) ? response[key] : [];

  if (!response && !!options.preloadedData) {
    return [options.preloadedData, {...queryResult, isLoading: false}]
  }

  return [entities, queryResult];
}

function useTransformedWhere<K extends keyof Namespaces, T = any>(
  key: K,
  where: WhereClause,
  transform: (data: InstantObject) => T,
  options: UseWhereOptions<K> = {},
): [NonNullable<T>[], ReturnType<typeof useQuery>] {
  const [entities, queryResult] = useWhere(key, where, options);
  const transformed_entities = entities?.map(x => transform(x)).filter(x => !!x) as NonNullable<T>[] ?? [];
  return [transformed_entities, queryResult];
}


// Use split

interface UseSplitOptions<K extends keyof Namespaces> {
  preloadedData?: InstantObject;
  splitChildren?: ChildLinkTypes[K][];
  queryOptions?: QueryOptions<K>;
}

function useSplitData<K extends keyof Namespaces>(
  key: K,
  id: string = "never",
  options: UseSplitOptions<K> = {},
): [InstantObject | null, ReturnType<typeof useQuery>] {
  const _query = query_one(key, id, options.queryOptions);
  const query = JSON.parse(JSON.stringify({
    [key]: {
      ...Object.fromEntries(Object.entries(_query[key]).filter(x => !options.splitChildren?.find(y => y === x[0]))),
    }
  }));
  const _split_query = query_split(key, id, options?.queryOptions);
  const split_query = JSON.parse(JSON.stringify({
    ...Object.fromEntries(Object.entries(_split_query).filter(x => options.splitChildren?.find(y => y === x[0])))
  }));

  const [response, setResponse] = React.useState<ReturnType<typeof useInstantDBQuery> | null>(null);
  const [splitResponses, setSplitResponses] = React.useState<Record<string, ReturnType<typeof useInstantDBQuery>>>({});

  React.useEffect(() => {
    setResponse(null);
    setSplitResponses({});
    const unsubs: ReturnType<typeof instantDBCore.subscribeQuery>[] = [];

    // Listen for unsplit keys
    //console.log("Unsplit query: ", query);

    unsubs.push(instantDBCore.subscribeQuery(query, resp => {
      setResponse({...resp, isLoading: false })
    }));

    //console.log("Split query", split_query)

    // Add split keys individually
    Object.entries(split_query).forEach(([innerKey, value]) => {
      const innerQuery = { [innerKey]: value } as Query;
      unsubs.push(instantDBCore.subscribeQuery(innerQuery, (resp) => {
        setSplitResponses((prev) => {
          //console.log("response for", innerKey, resp);
          return {
            ...prev,
            [innerKey]: {
              isLoading: false,
              ...resp,
            }
          }
        });
      }))
    });

    return () => {
      unsubs.forEach((unsub) => unsub());
    };
  }, [JSON.stringify(query)]);

  const isLoading = response?.isLoading || Object.values(splitResponses).some((x) => x.isLoading);
  const error = (response?.error || Object.values(splitResponses).find((x) => !!x.error)?.error) as ReturnType<typeof useQuery>['error'];
  const data = {
    [key]: [{
      id: id,
      ...(response?.data?.[key]?.[0] || {}),
      ...{...Object.fromEntries(Object.entries(splitResponses).filter(([k, _]) => k !== key).map(([k, v]) => ([k, v.data?.[k] || {}])))}
    }]
  };
  const debugRef = React.useMemo(() => () => {}, []);

  const entity = data && (Array.isArray(data[key])) && data[key].length === 1 && data[key][0] !== null ? data[key][0] : null;

  if (isLoading && !!options.preloadedData) {
    //@ts-ignore
    return [options.preloadedData, {isLoading, error, data, debugRef, query}]
  }

  //@ts-ignore
  return [entity, {isLoading, error, data, debugRef, query}];

}

function useTransformedSplitData<K extends keyof Namespaces, T = any>(
  key: K,
  id: string = "never",
  transform: (data: InstantObject) => T,
  options: UseOptions<K> = {},
): [T | null, ReturnType<typeof useQuery>] {
  const [entity, queryResult] = useSplitData(key, id, options);
  const transformed = entity ? transform(entity) : null;
  return [transformed, queryResult];
}


// Use grandchildren

interface UseGrandchildrenOptions<K extends keyof Namespaces> {
  preloadedData?: InstantObject;
  queryOptions?: QueryOptions<K>;
}

function useGrandchildren<K extends keyof Namespaces>(
  key: K,
  id: string,
  options: UseGrandchildrenOptions<K> = {},
): [InstantObject | null, ReturnType<typeof useQuery>] {
  const _query = query_grandchildren(key, id, options?.queryOptions);
  const queryResult = useQuery(_query as Exactly<Query, Query>);
  const response = queryResult.data;

  const entity = response && (Array.isArray(response[key])) && response[key].length === 1 && response[key][0] !== null ? response[key][0] : null;

  if (entity === null && !!options.preloadedData) {
    return [options.preloadedData, {...queryResult, isLoading: false}]
  }

  return [entity, queryResult];

}

function useTransformedGrandchildren<K extends keyof Namespaces, T = any>(
  key: K,
  id: string = "never",
  transform: (data: InstantObject) => T,
  options: UseOptions<K> = {},
): [T | null, ReturnType<typeof useQuery>] {
  const [entity, queryResult] = useGrandchildren(key, id, options);
  return [entity ? transform(entity) : null, queryResult];
}


// Use deep

interface UseDeepOptions<K extends keyof Namespaces> {
  preloadedData?: InstantObject;
  depth: number;
  queryOptions?: QueryOptions<K>;
}

function useDeep<K extends keyof Namespaces>(
  key: K,
  id: string = "never",
  options: UseDeepOptions<K> = { depth: 1 },
): [InstantObject | null, ReturnType<typeof useQuery>] {
  const _query = query_deep(key, id, options.depth, options?.queryOptions);
  const queryResult = useQuery(_query as Exactly<Query, Query>);
  const response = queryResult.data;

  const entity = response && (Array.isArray(response[key])) && response[key].length === 1 && response[key][0] !== null ? response[key][0] : null;

  if (queryResult.isLoading && !!options.preloadedData) {
    return [options.preloadedData, {...queryResult, isLoading: false}]
  }

  return [entity, queryResult];
}

function useTransformedDeep<K extends keyof Namespaces, T = any>(
  key: K,
  id: string = "never",
  transform: (data: InstantObject) => T,
  options: UseDeepOptions<K> = { depth: 1 },
): [T | null, ReturnType<typeof useQuery>] {
  const [entity, queryResult] = useDeep(key, id, options);
  return [entity ? transform(entity) : null, queryResult];
}


// Use custom

interface UseCustomOptions<K extends keyof Namespaces> {
  preloadedData?: InstantObject[];
  queryOptions?: Omit<QueryOptions<K>, "expand_ancestors" | "expand_descendants" | "no_default_expansion">;
}

function useCustom<K extends keyof Namespaces>(
  key: K,
  namespaceVal: Query[string],
  options: UseCustomOptions<K> = {},
): [InstantObject[], ReturnType<typeof useQuery>] {
  const _query = query_custom(key, namespaceVal as WhereClause, options?.queryOptions);
  const queryResult = useQuery(_query as Exactly<Query, Query>);
  const response = queryResult.data;
  const entities = response && (response[key]) && (Array.isArray(response[key])) ? response[key] : [];

  if (!response && !!options.preloadedData) {
    return [options.preloadedData, {...queryResult, isLoading: false}]
  }

  return [entities, queryResult];
}

function useTransformedCustom<K extends keyof Namespaces, T = any>(
  key: K,
  namespaceVal: Query[string],
  transform: (data: InstantObject) => T,
  options: UseCustomOptions<K> = {},
): [NonNullable<T>[], ReturnType<typeof useQuery>] {
  const [entities, queryResult] = useCustom(key, namespaceVal, options);
  const transformed_entities = entities?.map(x => transform(x)).filter(x => !!x) as NonNullable<T>[] ?? [];
  return [transformed_entities, queryResult];
}


// Use many

interface UseManyOptions<K extends keyof Namespaces> {
  preloadedData?: InstantObject[];
  queryOptions?: QueryOptions<K>;
}

function useArray<K extends keyof Namespaces>(
  key: K,
  ids: string[],
  options: UseManyOptions<K> = {},
): [InstantObject[], ReturnType<typeof useQuery>] {

  const _query = query_many(key, ids.length > 0 ? ids : ["never"], options?.queryOptions);
  //console.log("useArray query", JSON.stringify(_query));
  const queryResult = useQuery(_query as Exactly<Query, Query>);
  //console.log("useArray queryResult", JSON.stringify(queryResult));
  const response = queryResult.data;
  const entities = response && (response[key]) && (Array.isArray(response[key])) ? response[key] : [];

  if (!response && !!options.preloadedData) {
    return [options.preloadedData, {...queryResult, isLoading: false}]
  }

  return [entities, queryResult];

}

function useTransformedArray<K extends keyof Namespaces, T = any>(
  key: K,
  ids: string[],
  transform: (data: InstantObject) => T,
  options: UseManyOptions<K> = {},
): [NonNullable<T>[], ReturnType<typeof useQuery>] {
  const [entities, queryResult] = useArray(key, ids, options);
  const transformed_entities = entities?.map(x => transform(x)).filter(x => !!x) as NonNullable<T>[] ?? [];
  return [transformed_entities, queryResult];
}


// Class definition

interface EntityStatic<
    K extends keyof Namespaces,
    T extends SchemaEntry<K>,
    DerivedClass extends Entity<EntityMap, K, T>,
  > extends EntityStatic_core<EntityMap, K, T, DerivedClass> {
  use(id?: string, options?: UseOptions<K>): ReturnType<typeof useTransformedData>;
  useWhere(where: WhereClause, options?: UseWhereOptions<K>): ReturnType<typeof useTransformedWhere>;
  useSplit(id?: string, options?: UseSplitOptions<K>): ReturnType<typeof useTransformedSplitData>;
  useGrandchildren(id?: string, options?: UseOptions<K>): ReturnType<typeof useTransformedGrandchildren>;
  useDeep(id?: string, options?: UseDeepOptions<K>): ReturnType<typeof useTransformedDeep>;
  useCustom(namespaceVal?: Query[string], options?: UseCustomOptions<K>): ReturnType<typeof useTransformedCustom>;
  useMany(ids: string[], options?: UseManyOptions<K>): ReturnType<typeof useTransformedArray>;
  useInContext(): DerivedClass | null;
  useManyInContext(): DerivedClass[];
  Provider: (props: {children: React.ReactNode, entity: DerivedClass | null}) => React.ReactNode;
  ArrayProvider: (props: {children: React.ReactNode, entities: DerivedClass[]}) => React.ReactNode;
  _initProvider(): void;
  _initArrayProvider(): void;
  initProviders(): void;
}

function createEntityClass<K extends keyof Namespaces, T extends SchemaEntry<K>, Namespace = Namespaces[K]>(
  key: K,
) {
  class NewClass extends createEntityClass_core<EntityMap, K, T, Namespace>(db, key) {

    // Hooks

    public static use<DerivedClass extends NewClass>(this: EntityStatic<K, T, DerivedClass>, id?: string, options?: UseOptions<K>) {
      return useTransformedData(key, id, data => data ? transform_data<EntityMap, K, DerivedClass>(db, key, data) : null, options)
    }
    public static useWhere<DerivedClass extends NewClass>(this: EntityStatic<K, T, DerivedClass>, where: WhereClause, options?: UseWhereOptions<K>) {
      return useTransformedWhere(key, where, data => data ? transform_data<EntityMap, K, DerivedClass>(db, key, data) : null, options)
    }
    public static useSplit<DerivedClass extends NewClass>(this: EntityStatic<K, T, DerivedClass>, id?: string, options?: UseSplitOptions<K>) {
      return useTransformedSplitData(key, id, data => data ? transform_data<EntityMap, K, DerivedClass>(db, key, data) : null, options)
    }
    public static useGrandchildren<DerivedClass extends NewClass>(this: EntityStatic<K, T, DerivedClass>, id?: string, options?: UseOptions<K>) {
      return useTransformedGrandchildren(key, id, data => data ? transform_data<EntityMap, K, DerivedClass>(db, key, data) : null, options)
    }
    public static useDeep<DerivedClass extends NewClass>(this: EntityStatic<K, T, DerivedClass>, id?: string, options?: UseDeepOptions<K>) {
      return useTransformedDeep(key, id, data => data ? transform_data<EntityMap, K, DerivedClass>(db, key, data) : null, options)
    }
    public static useCustom<DerivedClass extends NewClass>(this: EntityStatic<K, T, DerivedClass>, namespaceVal: Query[string], options?: UseCustomOptions<K>) {
      return useTransformedCustom(key, namespaceVal, data => data ? transform_data<EntityMap, K, DerivedClass>(db, key, data) : null, options)
    }
    public static useMany<DerivedClass extends NewClass>(this: EntityStatic<K, T, DerivedClass>, ids: string[], options?: UseManyOptions<K>) {
      return useTransformedArray(key, ids, data => data ? transform_data<EntityMap, K, DerivedClass>(db, key, data) : null, options)
    }

    // Providers

    public static useInContext: <DerivedClass extends NewClass>(this: EntityStatic<K, T, DerivedClass>) => DerivedClass | null;
    public static Provider: <DerivedClass extends NewClass>(this: EntityStatic<K, T, DerivedClass>, props: {children: React.ReactNode, entity: DerivedClass | null}) => React.ReactNode;
    public static useManyInContext: <DerivedClass extends NewClass>(this: EntityStatic<K, T, DerivedClass>) => DerivedClass[];
    public static ArrayProvider: <DerivedClass extends NewClass>(this: EntityStatic<K, T, DerivedClass>, props: {children: React.ReactNode, entities: DerivedClass[]}) => React.ReactNode;

    public static _initProvider<DerivedClass extends NewClass>(this: EntityStatic<K, T, DerivedClass>) {
      const context = React.createContext<DerivedClass | null>(null);
      this.Provider = (props: {children: React.ReactNode, entity: DerivedClass | null}): React.ReactNode => {
        return <context.Provider value={props.entity}>
          {props.children}
        </context.Provider>
      };
      this.useInContext = (): DerivedClass | null => React.useContext(context);
    }

    public static _initArrayProvider<DerivedClass extends NewClass>(this: EntityStatic<K, T, DerivedClass>) {
      const context = React.createContext<DerivedClass[]>([]);
      this.ArrayProvider = (props: {children: React.ReactNode, entities: DerivedClass[]}): React.ReactNode => {
        return <context.Provider value={props.entities}>
          {props.children}
        </context.Provider>
      };
      this.useManyInContext = (): DerivedClass[] => React.useContext(context);
    }

    public static initProviders<DerivedClass extends NewClass>(
      this: EntityStatic<K, T, DerivedClass>,
    ) {
      this._initProvider();
      this._initArrayProvider();
    }

  }
  return NewClass;
}


/**
 * ~~~~~~~~~~ START SCHEMA GENERATED CODE ~~~~~~~~~~
*/

export class UserProfile extends createEntityClass('profile') {}
export class InviteTicket extends createEntityClass('ticket__invite') {}
export class Workspace extends createEntityClass('workspace') {}
export class Project extends createEntityClass('project') {}
export class Category extends createEntityClass('category') {}
export class Asset extends createEntityClass('asset') {}
export class Task extends createEntityClass('task') {}
export class Phase extends createEntityClass('phase') {}
export class Doc extends createEntityClass('doc') {}
export class ProjectGroup extends createEntityClass('group__project') {}
export class CategoryGroup extends createEntityClass('group__category') {}
export class AssetGroup extends createEntityClass('group__asset') {}
export class WorkspaceAdminRole extends createEntityClass('role__workspace__admin') {}
export class ProjectAdminRole extends createEntityClass('role__project__admin') {}
export class ProjectEditorRole extends createEntityClass('role__project__editor') {}
export class TaskAssigneeRole extends createEntityClass('role__task__assignee') {}
export class ProjectCategoryListItem extends createEntityClass('list_item__category__in__project') {}
export class ProjectCategoryGroupListItem extends createEntityClass('list_item__group__category__in__project') {}
export class CategoryGroupCategoryListItem extends createEntityClass('list_item__category__in__group__category') {}
export class CategoryAssetListItem extends createEntityClass('list_item__asset__in__category') {}
export class CategoryAssetGroupListItem extends createEntityClass('list_item__group__asset__in__category') {}
export class AssetGroupAssetListItem extends createEntityClass('list_item__asset__in__group__asset') {}
export class AssetTaskListItem extends createEntityClass('list_item__task__in__asset') {}
export class FileEntry extends createEntityClass('file_entry') {}
export class Comment extends createEntityClass('comment') {}
export class Drawover extends createEntityClass('drawover') {}
export class FileEntryAttachment extends createEntityClass('attachment__file_entry') {}
export class DiscordUploadAttachmentTicket extends createEntityClass('ticket__discord_upload_attachment') {}
export class Notification extends createEntityClass('notification') {}

UserProfile.initProviders();
InviteTicket.initProviders();
Workspace.initProviders();
Project.initProviders();
Category.initProviders();
Asset.initProviders();
Task.initProviders();
Phase.initProviders();
Doc.initProviders();
CategoryGroup.initProviders();
AssetGroup.initProviders();
ProjectGroup.initProviders();
WorkspaceAdminRole.initProviders();
ProjectAdminRole.initProviders();
ProjectEditorRole.initProviders();
TaskAssigneeRole.initProviders();
ProjectCategoryListItem.initProviders();
ProjectCategoryGroupListItem.initProviders();
CategoryAssetListItem.initProviders();
CategoryAssetGroupListItem.initProviders();
AssetTaskListItem.initProviders();
FileEntry.initProviders();
Comment.initProviders();
Drawover.initProviders();
FileEntryAttachment.initProviders();
DiscordUploadAttachmentTicket.initProviders();
Notification.initProviders();

export interface EntityMap extends Record<keyof Namespaces, any> {

  'profile': UserProfile,
  'ticket__invite': InviteTicket,
  'workspace': Workspace,
  'project': Project,
  'category': Category,
  'asset': Asset,
  'task': Task,
  'phase': Phase,
  'doc': Doc,
  'group__project': ProjectGroup,
  'group__category': CategoryGroup,
  'group__asset': AssetGroup,
  'role__workspace__admin': WorkspaceAdminRole,
  'role__project__admin': ProjectAdminRole,
  'role__project__editor': ProjectEditorRole,
  'role__task__assignee': TaskAssigneeRole,
  'list_item__category__in__project': ProjectCategoryListItem,
  'list_item__group__category__in__project': ProjectCategoryGroupListItem,
  'list_item__category__in__group__category': CategoryGroupCategoryListItem,
  'list_item__asset__in__category': CategoryAssetListItem,
  'list_item__group__asset__in__category': CategoryAssetGroupListItem,
  'list_item__asset__in__group__asset': AssetGroupAssetListItem,
  'list_item__task__in__asset': AssetTaskListItem,
  'file_entry': FileEntry,
  'comment': Comment,
  'drawover': Drawover,
  'attachment__file_entry': FileEntryAttachment,
  'ticket__discord_upload_attachment': DiscordUploadAttachmentTicket,
  'notification': Notification,

};

/**
 * ~~~~~~~~~~ END SCHEMA GENERATED CODE ~~~~~~~~~~
*/
