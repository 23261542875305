import { Asset, Category, Project, Workspace } from "@palette.tools/model.client";
import { Group, Item } from "@palette.tools/react.dogs";
import React from "react";


export interface AssetDependencyEditorCategoryItem {
  rowType: 'category',
  id: string,
  title: string,
  emoji: string,
  updated_at: number,
}


export interface AssetDependencyEditorAssetItem {
  rowType: 'asset',
  id: string,
  title: string,
  updated_at: number,
}


export type AssetDependencyEditorItem = AssetDependencyEditorCategoryItem | AssetDependencyEditorAssetItem;


export interface AssetDependencyEditorModel {
  workspace: Workspace | null,
  project: Project | null,
  asset: Asset,
  inputs: Asset[],
  outputs: Asset[],
  categories: Category[],
  assetsByCategory: Record<string, Asset[]>,
}


export interface AssetDependencyEditorCallbacks {
  onAddInputs: (asset: Asset, inputs: string[]) => Promise<void>,
  onRemoveInputs: (asset: Asset, inputs: string[]) => Promise<void>,
  onAddOutputs: (asset: Asset, outputs: string[]) => Promise<void>,
  onRemoveOutputs: (asset: Asset, outputs: string[]) => Promise<void>,
}


export const constructAssetTree = ({
  categories,
  assetsByCategory,
  includeAsset = () => true,
}: {
} & AssetDependencyEditorModel & {
  includeAsset: (asset: Asset) => boolean,
}) => {

  let lastModified = 0;

  return categories
    .sort((a, b) => (a.data.name || "").localeCompare(b.data.name || ""))
    .reduce((acc, category) => {
      let { items, rowsById, ancestorsById, lastModified } = acc;
      ancestorsById[category.id] = [];
      lastModified = Math.max(lastModified, category.data.updated_at || 0);

      const [categoryItems] = assetsByCategory[category.id]
        .filter(includeAsset)
        .sort((a, b) => (a.data.name || "").localeCompare(b.data.name || ""))
        .reduce((acc, asset) => {
          const [categoryItems] = acc;
          const assetId = category.id + ":" + asset.id;

          const assetItem: Item<AssetDependencyEditorItem> = {
            __id: assetId,
            data: {
              rowType: 'asset' as const,
              id: asset.id,
              title: asset.data.name || "",
              updated_at: asset.data.updated_at || 0,
            },
          };

          categoryItems.push(assetItem);
          rowsById[assetId] = assetItem.data;
          
          return [categoryItems] as [Item<AssetDependencyEditorItem>[]];
        }, [[] as Item<AssetDependencyEditorItem>[]]);
      
      const categoryRow: Group<AssetDependencyEditorItem> = {
        __id: category.id,
        data: {
          rowType: 'category' as const,
          id: category.id,
          title: category.data.name || "",
          emoji: category.data.emoji || "",
          updated_at: category.data.updated_at || 0,
        },
        __children: [
          ...categoryItems,
        ],
      };

      // Don't include empty categories
      if (categoryItems.length > 0) {
        items.push(categoryRow);
        rowsById[category.id] = categoryRow.data;
      }

      return {
        items,
        rowsById,
        ancestorsById,
        lastModified,
      }
    }, {
      items: [],
      rowsById: {},
      ancestorsById: {},
      lastModified: 0,
    } as {
      items: (Item<AssetDependencyEditorItem> | Group<AssetDependencyEditorItem>)[],
      rowsById: Record<string, AssetDependencyEditorItem>,
      ancestorsById: Record<string, string[]>,
      lastModified: number,
    });

}


export type AssetDependencyEditorTree = ReturnType<typeof constructAssetTree>;



export const useAssetDependencyEditor = (model: AssetDependencyEditorModel) => {
  const {
    inputTree,
    inputAvailableTree,
    outputTree,
    outputAvailableTree,
    inputIds,
    outputIds,
  } = React.useMemo(() => {

    const inputIds = new Set(model.inputs.map(input => input.id));
    const outputIds = new Set(model.outputs.map(output => output.id));

    return {
      inputTree: constructAssetTree({
        ...model,
        includeAsset: (asset) => inputIds.has(asset.id),
      }),
      inputAvailableTree: constructAssetTree({
        ...model,
        includeAsset: (asset) => !inputIds.has(asset.id),
      }),
      outputTree: constructAssetTree({
        ...model,
        includeAsset: (asset) => outputIds.has(asset.id),
      }),
      outputAvailableTree: constructAssetTree({
        ...model,
        includeAsset: (asset) => !outputIds.has(asset.id),
      }),
      inputIds,
      outputIds,
    }
  }, [model]);

  return {
    inputTree,
    inputAvailableTree,
    outputTree,
    outputAvailableTree,
    inputIds,
    outputIds,
  }
}
