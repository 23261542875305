import { useAuth } from "./auth";
import { Asset, Category, Comment, Phase, Project, Task, UserProfile, Workspace } from "./model";


export interface PermissionsProps {
  profile?: UserProfile | null,
  workspace?: Workspace | null,
  project?: Project | null,
  category?: Category | null,
  asset?: Asset | null,
  task?: Task | null,
  phase?: Phase | null,
  comment?: Comment | null,
}

export function getPermissions(props: PermissionsProps) {
  const {
    profile,
    workspace,
    project,
    category,
    asset,
    task,
    phase,
    comment,
  } = props;

  const isWorkspaceAdmin = !!profile && workspace?.is_role("admin", profile.id) || false;
  const isWorkspaceMember = !!profile && !!workspace?.links.profile?.find(x => x.id === profile.id) || false;
  const isProjectAdmin = !!profile && project?.is_role("admin", profile.id) || false;
  const isProjectReviewer = !!profile && project?.is_role("reviewer", profile.id) || false;
  const isProjectEditor = !!profile && project?.is_role("editor", profile.id) || false;

  return {

    isWorkspaceAdmin,
    isWorkspaceMember,
    isProjectAdmin,
    isProjectReviewer,
    isProjectEditor,

    canCreateWorkspace: isWorkspaceAdmin || false,
    canEditWorkspace: !!workspace && isWorkspaceAdmin || false,
    canEditWithinWorkspace: !!workspace && isWorkspaceMember || false,
    canDeleteWorkspace: !!workspace && isWorkspaceAdmin || false,
    canManageWorkspaceUsers: !!workspace && isWorkspaceAdmin || false,

    canCreateProject: !!workspace && isWorkspaceAdmin || false,
    canEditProject: !!project && (isWorkspaceAdmin || isProjectAdmin) || false,
    canEditWithinProject: !!project && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,
    canDeleteProject: !!project && (isWorkspaceAdmin || isProjectAdmin) || false,
    canManageProjectUsers: !!project && (isWorkspaceAdmin || isProjectAdmin) || false,

    canCreateCategory: !!project && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,
    canEditCategory: !!category && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,
    canDeleteCategory: !!category && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,

    canCreateAsset: !!project && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,
    canEditAsset: !!asset && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,
    canDeleteAsset: !!asset && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,

    canCreateTask: !!asset && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,
    canEditTask: !!task && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,
    canDeleteTask: !!task && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,

    canCreatePhase: !!project && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,
    canEditPhase: !!phase && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,
    canDeletePhase: !!phase && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,

    canCreateComment: !!profile && isWorkspaceMember || false,
    canEditComment: !!profile && !!comment && comment.data.created_by === profile?.id || false,
    canDeleteComment: !!profile && !!comment && comment.data.created_by === profile?.id || isWorkspaceAdmin || isProjectAdmin || false,

    canCreateFile: !!profile && !!task && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,
    canEditFile: !!profile && !!task && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,
    canDeleteFile: !!profile && !!task && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,

  }
}


export function usePermissions(props: PermissionsProps) {

  const { profile } = useAuth();
  const workspace = Workspace.useInContext() || props.workspace;
  const project = Project.useInContext() || props.project;
  const category = Category.useInContext() || props.category;
  const asset = Asset.useInContext() || props.asset;
  const task = Task.useInContext() || props.task;
  const phase = Phase.useInContext() || props.phase;
  const comment = Comment.useInContext() || props.comment;

  return {...getPermissions({
      profile,
      workspace,
      project,
      category,
      asset,
      task,
      phase,
      comment,
    }),
    context: {...props, profile},
  }

}
